import axios from 'axios';

const BASE_URL = "https://www.dnd5eapi.co";
const REST_ROOT = "/api/2014";
const GRAPHQL_ROOT = "/graphql/2014";

const rest_instance = axios.create({
    baseURL: BASE_URL + REST_ROOT,
});

const gql_instance = axios.create({
    baseURL: BASE_URL + GRAPHQL_ROOT,
    headers: {
        'Content-Type': 'application/json'
    }
});

export {rest_instance, gql_instance};

